export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.config.errorHandler = (error) => {
    if (import.meta.client) {
      errorTracker(error)

      const defaultErrorMessage = 'Ocorreu um erro inesperado. Tente novamente.'

      toast({
        severity: 'error',
        summary: 'Erro',
        detail: (error as Error).message ? (error as Error).message : defaultErrorMessage,
      })
    }
  }

  nuxtApp.hook('vue:error', (error: Error | any): void => {
    if (import.meta.client) {
      errorTracker(error)

      const defaultErrorMessage = 'Ocorreu um erro inesperado. Tente novamente.'

      toast({
        severity: 'error',
        summary: 'Erro',
        detail: (error as Error).message ? (error as Error).message : defaultErrorMessage,
      })
    }
  })
})
